<template>
  <div>
    <vs-row>
      <vx-card>
        <vs-col class="flex my-2" vs-w="12" vs-sm="12" vs-xs="12">
          <vs-input
            v-model="search"
            @keyup.enter="getAllHosts()"
            label-placeholder="search key"
          ></vs-input>
          <vs-input
            v-model="user_id"
            @keyup.enter="getAllHosts()"
            label-placeholder="user_id"
          >
          </vs-input>
          <vs-pagination
            :total="last_page"
            v-model="current_page"
            :value="current_page || 0"
          ></vs-pagination>
        </vs-col>
        <!-- <vs-button @click="getAllHosts()">test</vs-button> -->

        <vs-col vs-w="12">
          <vs-table
            @dblSelection="doubleSelection"
            v-model="selectedhost"
            :data="hostlist"
          >
            <template slot="thead">
              <vs-th>ID هاست</vs-th>
              <vs-th>شماره هاست</vs-th>
              <vs-th>نوع هاست</vs-th>
              <vs-th>اسم دامنه</vs-th>
              <vs-th>اسم هاست</vs-th>
              <vs-th>وضعیت</vs-th>
              <vs-th>هزینه ماهانه</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">
                  {{ data[indextr].id }}
                </vs-td>
                <vs-td :data="data[indextr].hostplan">
                  {{ data[indextr].host_user }}
                </vs-td>
                <vs-td :data="data[indextr]">
                  {{
                    `${data[indextr].hostplan.plan_title}-${data[indextr].hostplan.plan_type_text}`
                  }}
                </vs-td>
                <vs-td :data="data[indextr]">
                  {{ data[indextr].domain_name }}
                </vs-td>
                <vs-td :data="data[indextr].host_title">
                  {{ data[indextr].host_title }}
                </vs-td>
                <vs-td :data="data[indextr]">
                  {{ data[indextr].host_status_text }}
                </vs-td>
                <vs-td :data="data[indextr]">
                  {{ data[indextr].user_current_pay_month }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <!-- <p>{{ selectedhost }}</p>
          -->
          <vue-json-pretty :data="selectedhost"> </vue-json-pretty>
        </vs-col>
      </vx-card>
    </vs-row>
  </div>
</template>
<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  components: {
    VueJsonPretty,
  },
  watch: {
    current_page() {
      this.getAllHosts();
    },
  },
  data() {
    return {
      hostlist: [],
      selectedhost: {},
      search: "",
      user_id: "",

      //PAGINATION ===================
      current_page: 0,
      last_page: 0,
      //PAGINATION ===================
    };
  },
  methods: {
    getAllHosts() {
      this.$vs.loading()
      this.$http
        .get(
          `/admin/host/lists?user_id=${this.user_id}&search=${this.search}&page=${this.current_page}`
        )
        .then((res) => {
          this.$vs.loading.close()
          this.hostlist = res.data.params.data;
          this.last_page = res.data.params.last_page;
        })
        .catch((err) => {
          this.$vs.loading.close()
          console.log(err);
        });
    },
    doubleSelection() {
      this.$router.push(`/user?id=${this.selectedhost.user_id}`);
    },
  },
  created() {
    this.getAllHosts();
  },
};
</script>